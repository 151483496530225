import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faFile, faWallet, faAddressCard, faGear, faBars , faGrip } from '@fortawesome/free-solid-svg-icons';
import { NavLink, Outlet } from 'react-router-dom';

export default function Sidebar() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
     const handlesidebarclose = () => {
        setIsSidebarOpen(!isSidebarOpen);

    }
    const handleSignOut = () => {
        // Clear login information
        localStorage.removeItem('loginResult');  // Clear localStorage

        // Redirect to login page
        navigate('/', { replace: true });
    };
    return (
        <div className={`container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
            <div className="top-bar">
                <div className="top-bar-left">
                    <div className="top-bar-logo">
                        <img src="/images/logo.jfif" alt="IndusViva" />
                        <span>SHIVA SAI MARKETING</span>
                    </div>
                    <FontAwesomeIcon icon={faBars} className="menu-icon" onClick={toggleSidebar} />
                </div>
                <div className="user-profile">
                    <div className="user-icon" onClick={toggleMenu}>
                        <img src="/images/profile.png" alt="User Profile" className="profile-pic" />
                    </div>
                    {isMenuOpen && (
                        <div className="user-menu">
                            <div className="user-info">
                                <span className="user-id">Admin</span>
                            </div>
                            <ul className="menu-options">
                                <a href='Profile'><li>My Profile</li></a>
                                <a href='Settings'><li>Settings</li></a>
                                <li onClick={handleSignOut}>Signout / Switch Account</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <div className="content-wrapper">
                <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
                    <ul className="sidebar-menu">
                        {/* <NavLink  onClick={'handlesidebarclose'} to="" activeClassName="active">
                            <li>
                                <FontAwesomeIcon icon={faGrip} />
                                <span className="menu-text">{isSidebarOpen && 'Dashboard'}</span>
                            </li>
                        </NavLink> */}
                        <NavLink onClick={handlesidebarclose} to="Member_management">
                            <li>
                                <FontAwesomeIcon icon={faUsers} />
                                <span className="menu-text">{isSidebarOpen && 'Reports Management'}</span>
                            </li>
                       </NavLink>
                        {/* <NavLink  onClick={handlesidebarclose} to="">
                            <li>
                                <FontAwesomeIcon icon={faFile} />
                                <span className="menu-text">{isSidebarOpen && 'Reports Management'}</span>
                            </li>
                        </NavLink> */}
                        {/* <NavLink  onClick={handlesidebarclose} to="">
                            <li>
                                <FontAwesomeIcon icon={faWallet} />
                                <span className="menu-text">{isSidebarOpen && 'Wallet Management'}</span>
                            </li>
                        </NavLink> */}
                        <NavLink  onClick={handlesidebarclose} to="">

                        <li>
                                <FontAwesomeIcon icon={faAddressCard} />
                            <span className="menu-text">{isSidebarOpen && 'KYC Management'}</span>
                        </li>
                        </NavLink>
                        {/* <NavLink  onClick={handlesidebarclose} to="">
                        <li>
                                <FontAwesomeIcon icon={faGear} />
                            <span className="menu-text">{isSidebarOpen && 'Settings Management'}</span>
                        </li>
                        </NavLink> */}

                    </ul>
                </div>
                <div className="smain-content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}
