import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useIdleTimer = (timeout = 300000) => {
    const navigate = useNavigate();

    useEffect(() => {
        let idleTimeout;

        const resetTimeout = () => {
            if (idleTimeout) clearTimeout(idleTimeout);
            idleTimeout = setTimeout(() => {
                // Redirect to login after inactivity timeout and replace history to prevent going back
                navigate('/', { replace: true });
            }, timeout);
        };

        const handleActivity = () => {
            resetTimeout();
        };

        // Add event listeners to detect user activity
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);
        window.addEventListener('click', handleActivity);
        window.addEventListener('touchstart', handleActivity);

        // Reset the timer when the component mounts
        resetTimeout();

        // Cleanup event listeners on unmount
        return () => {
            if (idleTimeout) clearTimeout(idleTimeout);
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            window.removeEventListener('click', handleActivity);
            window.removeEventListener('touchstart', handleActivity);
        };
    }, [navigate, timeout]);
};

export default useIdleTimer;
