import React, { useState, createContext } from 'react';

// Create a LoginContext
export const LoginContext = createContext();

// Create a provider component
export const LoginProvider = ({ children }) => {
    const [loginResult, setLoginResult] = useState(() => {
        // Check if login data exists in localStorage and use it as the initial state
        const storedLogin = localStorage.getItem('loginResult');
        return storedLogin ? JSON.parse(storedLogin) : null;
    });

    return (
        <LoginContext.Provider value={{ loginResult, setLoginResult }}>
            {children}
        </LoginContext.Provider>
    );
};
