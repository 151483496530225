import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from './LoginContext';
import axios from 'axios'; // Import Axios for HTTP requests
import './Login.css';
import Url from '../Virtualoffice/Application/Url';

export default function Office() {
    const [sellerId, setSellerId] = useState();
    const [password, setPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);
    //username :ssm_guptha pasword :Ssm@guptha2024

    const navigate = useNavigate();
    const { setLoginResult } = useContext(LoginContext); // Get the login result setter from context

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // Axios is used to make the API call when the form is submitted
    const handleSubmit = async (e) => {
        e.preventDefault();
        const report_id = 1;
        const params = JSON.stringify({ report_id, username: sellerId, password });

        const apiUrl = `${Url.URL}/getadminreport?data=${encodeURIComponent(params)}`;

        try {
            const response = await axios.get(apiUrl);
            const loginDataArray = response.data;

            if (Array.isArray(loginDataArray) && loginDataArray.length > 0) {
                const loginData = loginDataArray[0];

                if (loginData[0].isvalid_Admin === 1) {
                    // Login is successful
                    setLoginResult(loginData); // Save the login data in context
                    localStorage.setItem('loginResult', JSON.stringify(loginData)); // Persist login in localStorage
                    navigate('/Sidebar/Member_management');
                } else {
                    alert('Invalid UserName or Password');
                }
            } else {
                alert('Invalid UserName or Password');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            alert('An error occurred while trying to log in. Please try again later.');
        }
    };

    return (
        <div className="main_div" style={{ backgroundImage: `url('/images/officeback.jpg')` }}>
            <div className="login_container">
                <div className="logo">
                    <img src="/images/logo.jfif" alt="Logo" />
                </div>
                <h2 className="title">SHIVA SAI MARKETING</h2>
                <form onSubmit={handleSubmit}>
                    <div className="input_group">
                        <input
                            type="text"
                            placeholder="User name"
                            value={sellerId}
                            onChange={(e) => setSellerId(e.target.value)}
                        />
                    </div>
                    <div className="input_group">
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="show_password">
                        <input
                            type="checkbox"
                            checked={showPassword}
                            onChange={handleShowPassword}
                        /> Show Password
                    </div>

                    <button type="submit" className="sign_in_btn">SIGN IN</button>
                </form>
            </div>
        </div>
    );
}
