import './App.css';
import React, { useContext , useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Office from './components/Virtualoffice/Login';
import Sidebar from './components/Virtualoffice/Application/Sidebar';
import Member_management from './components/Virtualoffice/Application/Member_management';
import Member_report from './components/Virtualoffice/Application/Member_report';
import { LoginContext, LoginProvider } from './components/Virtualoffice/LoginContext'; // Import your LoginContext
import useIdleTimer from './components/Virtualoffice/Application/UseIdleTimer'; // Import the Idle Timer hook

function App() {
  useEffect(() => {
    const disableRightClick = (event) => {
      event.preventDefault();
    };

    const disableF12 = (event) => {
      const key = event.key.toLowerCase();  // Convert the key to lowercase to ensure case-insensitive comparison

      if (
        key === "f12" ||  // F12: Opens Developer Tools in most browsers
        (event.ctrlKey && event.shiftKey && key === "i") ||  // Ctrl+Shift+I: Opens the Developer Tools with the Inspect Element tool active
        (event.ctrlKey && event.shiftKey && key === "c") ||  // Ctrl+Shift+C: Opens Developer Tools with the Inspect Element mode active (similar to Ctrl+Shift+I)
        (event.ctrlKey && event.shiftKey && key === "j") ||  // Ctrl+Shift+J: Opens the JavaScript console
        (event.ctrlKey && event.shiftKey && key === "k") ||  // Ctrl+Shift+K: Opens the JavaScript console in Firefox
        (event.ctrlKey && event.shiftKey && key === "s") ||  // Ctrl+Shift+S: Opens Developer Tools with the Debugger tool active in some browsers
        (event.ctrlKey && event.shiftKey && key === "e") ||  // Ctrl+Shift+E: Opens the Network tab in Developer Tools in Firefox
        (event.ctrlKey && event.shiftKey && key === "p") ||  // Ctrl+Shift+P: Opens the command menu in Chrome DevTools
        (event.ctrlKey && event.shiftKey && key === "m") ||  // Ctrl+Shift+M: Toggles the device toolbar (mobile view) in Chrome DevTools
        (event.ctrlKey && key === "u") ||  // Ctrl+U: Opens the View Page Source window
        (event.ctrlKey && key === "f8") ||  // Ctrl+F8: Pauses script execution in some browsers
        (event.ctrlKey && key === "f9") ||  // Ctrl+F9: Opens the Sources tab in Chrome DevTools
        (event.ctrlKey && key === "f10") ||  // Ctrl+F10: Steps over the next function call in the JavaScript debugger
        (event.ctrlKey && key === "f11")  // Ctrl+F11: Steps into the next function call in the JavaScript debugger
      ) {
        event.preventDefault();  // Prevent the default action associated with these key combinations
      }
    };

    const isTouchDevice = "ontouchstart" in document.documentElement;

    if (!isTouchDevice) {
      // Attach the event listeners only for non-touch devices
      document.addEventListener("contextmenu", disableRightClick);
      document.addEventListener("keydown", disableF12);
    }

    // Cleanup event listeners when the component unmounts
    return () => {
      if (!isTouchDevice) {
        document.removeEventListener("contextmenu", disableRightClick);
        document.removeEventListener("keydown", disableF12);
      }
    };
  }, []);
  return (
    <LoginProvider>
      <Router>
        <div className="App">
          <MainContent />
        </div>
      </Router>
    </LoginProvider>
  );
}

// ProtectedRoute component to ensure route protection
function ProtectedRoute({ element }) {
  const { loginResult } = useContext(LoginContext);

  if (!loginResult) {
    return <Navigate to="/" replace />;
  }

  return element;
}

function MainContent() {
  // Initialize the idle timer with a 5-minute timeout
  useIdleTimer(300000); // 5 minutes in milliseconds

  return (
    <Routes>
      <Route path="/" element={<Office />} /> {/* Login route */}
      <Route path="/Sidebar" element={<ProtectedRoute element={<Sidebar />} />}>
        <Route path="Member_management" element={<ProtectedRoute element={<Member_management />} />} />
        <Route path="Member_Reports" element={<ProtectedRoute element={<Member_report />} />} />
        {/* Add other routes here */}
      </Route>
    </Routes>
  );
}

export default App;