import React, { useState, useEffect, useContext } from 'react';
import './Member_management.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom


export default function Member_management() {
    const navigate = useNavigate(); // Initialize useNavigate

    const reports = [
        {
            title: 'Member List',
            description: 'Retrieve the details of all members in your network and explore growth potential.',
            report_id: 2
        },
        {
            title: 'Member Active List',
            description: 'Review the active members in your network and assess their ranks and special statuses.',
            report_id: 3
        },
        {
            title: 'Member Inactive List',
            description: 'Identify inactive members in your network and understand the reasons behind inactivity.',
            report_id: 4
        },
        {
            title: 'Member Orders',
            description: 'Monitor and track all orders placed by members within your network.',
            report_id: 5
        },
        {
            title: 'Member Payment Pendings',
            description: 'View pending payments and transactions that require approval in your network.',
            report_id: 6
        },
        {
            title: 'Member Payment Approved',
            description: 'Access reports on payments that have been approved and completed in your network.',
            report_id: 8
        },
        {
            title: 'Daily Payout Report',
            description: 'Analyze daily payout transactions and financial summaries for your network.',
            report_id: 9
        },
    ];

    const handleNavigation = (link, report_id) => {
        // console.log('Navigating to:', link, 'with report_id:', report_id);
        navigate('/Sidebar/Member_Reports', { state: { report_id } });
    };

    return (
        <section className="report-container">
            {reports.map((report, index) => (
                <div className="report-card" key={index}>
                    <div className="report-header">
                        <h3>{report.title}</h3>
                    </div>
                    <p>{report.description}</p>
                    <button
                        onClick={() => handleNavigation(report.link, report.report_id)}
                        className="proceed-button"
                    >
                        PROCEED <i className="fa fa-arrow-right"></i>
                    </button>
                </div>
            ))}
        </section>
    );
}
