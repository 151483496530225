import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx'; // Import XLSX for Excel export
import './Member_management.css';
import Url from './Url'; // Ensure Url file exists and exports the correct URL

export default function Enrollmentreport() {
    const [reportData, setReportData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date()); // State for date selection
    const location = useLocation();
    const { report_id } = location.state || {};

    useEffect(() => {
        fetchData(selectedDate); // Fetch data on initial load or when date changes
    }, [report_id, selectedDate]);

    const fetchData = (date) => {
        const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
        const params = JSON.stringify({ report_id });
        const apiUrl = `${Url.URL}/getadminreport?data=${params}`;

        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data) && data.length > 0) {
                    setReportData(data[0]);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const handleApprove = (paymentId, verified) => {
        if (verified === 'Approved') {
            console.log('This order is already approved.');
            return;
        }

        const isConfirmed = window.confirm('Do you want to approve this payment?');
        if (!isConfirmed) {
            console.log('Approval canceled.');
            return;
        }

        const params = JSON.stringify({ report_id: 7, payment_id: paymentId });
        const approveApiUrl = `${Url.URL}/getadminreport?data=${params}`;

        fetch(approveApiUrl)
            .then(response => response.json())
            .then(data => {
                console.log('API Response:', data);
                if (Array.isArray(data) && data.length > 0) {
                    setReportData(data);
                }
            })
            .catch(error => console.error('Error approving order:', error));
    };

    const handleExportToExcel = () => {
        if (reportData.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(reportData); // Convert JSON to worksheet
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "ReportData");
            XLSX.writeFile(workbook, `${getReportTitle()}_${new Date().toISOString()}.xlsx`);
        } else {
            alert("No data available to export.");
        }
    };

    const getReportTitle = () => {
        switch (report_id) {
            case 2:
                return "Member List";
            case 3:
                return "Member Active List";
            case 4:
                return "Member Inactive List";
            case 5:
                return "Member Orders";
            case 6:
                return "Member Payment Pendings";
            case 8:
                return "Member Payment Approved";
            case 9:
                return "Daily Payout Report";
            default:
                return "";
        }
    };

    return (
        <div className="enrollment-report-container">
            <div className="report-header">
                <h2 className="report-title">{getReportTitle()}</h2>

                <button className='export_btn' onClick={handleExportToExcel}>Export to Excel</button>
            </div>
            <div className="report-container">
                <div className="report-table-wrapper">
                    <table className="report-table">
                        <thead>
                            <tr>
                                {Object.keys(reportData[0] || {}).map((key, index) => (
                                    <th key={index}>{key.replace(/_/g, ' ')}</th>
                                ))}
                                {report_id === 6 && <th>Approved</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {reportData.map((item, index) => (
                                <tr key={index}>
                                    {Object.keys(item).map((key, subIndex) => (
                                        <td key={subIndex}>{String(item[key])}</td>
                                    ))}
                                    {report_id === 6 && (
                                        <td>
                                            <button className='appr_btn' onClick={() => handleApprove(item.payment_id, item.verified)}>
                                                Approve
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="report-cards_rep">
                    {reportData.map((item, index) => (
                        <div key={index} className="report-card">
                            {Object.entries(item).map(([key, value], subIndex) => (
                                <div key={subIndex}>
                                    <strong>{key.replace(/_/g, ' ')}:</strong> {String(value)}
                                </div>
                            ))}
                            {report_id === 6 && (
                                <button className='appr_btn' onClick={() => handleApprove(item.payment_id, item.verified)}>
                                    Approve
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
